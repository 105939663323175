import {Component, OnInit} from '@angular/core';
import {Global} from '../global';
import {ActivatedRoute, Router} from '@angular/router';

declare var $;

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {

  siniestro;
  documentos;
  documentosEnviados = [];
  documentosPendientes = [];
  exists;
  cargado;
  docsSubir = {};
  n_files = 1;
  paso = 1;
  no_data;
  subiendo = false;
  cargando = false;
  showBMN = false;
  showLEVANTE = false;
  showUBICA = false;
  docsSubidos = 0;

  constructor(public global: Global, private activatedRoute: ActivatedRoute, private router: Router) {

  }

  async ngOnInit() {
    this.no_data = false;
    if (!this.activatedRoute.params['value'].sin_id && !this.activatedRoute.params['value'].pol_id) {
      this.no_data = true;
      this.cargado = true;
      return;
    }

    this.cargado = false;
    this.exists = false;
    this.siniestro = null;
    await this.getDatosSiniestro();
    if (this.siniestro) await this.getDocsSiniestro();
    this.cargado = true;

    setTimeout(() => {
      $('#docsTable').DataTable({
        //lengthMenu: [5, 10, 20],
        lengthMenu: [50],
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
        },
      });
    }, 100);

  }

  async getDatosSiniestro() {

    this.cargando = true;

    let result = await this.global.apiCall("documentacionSiniestros/getSiniestro", "POST", false, {
      sin_id: this.activatedRoute.params['value'].sin_id,
      pol_id: this.activatedRoute.params['value'].pol_id
    });

    console.log(result);
    this.cargando = false;
    if (!result.status) {
      this.no_data = true;
      return;
    }
    result.data.Fecha = new Date(result.data.Fecha).toLocaleDateString("fr-FR");
    this.siniestro = result.data;
    if (this.siniestro.Cia == null || this.siniestro.Cia == '') this.siniestro.Cia = 'Sin datos';
    if (this.siniestro.Fecha == null || this.siniestro.Fecha == '') this.siniestro.Fecha = 'Sin datos';
    if (this.siniestro.pol_oficode == 32) {
      this.showBMN = true;
      document.documentElement.style.setProperty('--main-color', '#ee2464');
    }
    if (this.siniestro.pol_oficode == 2048) {
      this.showLEVANTE = true;
    }
    if (this.siniestro.pol_oficode == 4) {
      this.showUBICA = true;
      document.documentElement.style.setProperty('--main-color', '#2eaadc');
    }
  }

  async getDocsSiniestro() {

    this.cargando = true;

    let result = await this.global.apiCall("documentacionSiniestros/getDocsSiniestro", "POST", false, {
      sin_id: this.activatedRoute.params['value'].sin_id,
      id_peticion: this.activatedRoute.params['value'].id_peticion
    });

    this.cargando = false;
    if (!result.status) {

      this.no_data = true;
      return;

    }
    this.documentos = result.data;
    for (let doc of this.documentos) {
      console.log('doc', doc);
      if (doc['Estado'] != 'Pendiente') this.documentosEnviados.push(doc);
      if (doc['Estado'] == 'Pendiente') this.documentosPendientes.push(doc);
    }
    if (this.documentosPendientes.length == 0) {
      this.exists = true;
      this.paso = 2;
    } else {
      for (let doc of this.documentosPendientes) {
        this.docsSubir[doc.Documento] = [];
      }
    }
  }


  async subir_documento(fileInput: any, documentName, sinDocId) {

    this.cargando = true;

    let files = <Array<File>>fileInput.target.files;

    const docName = documentName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    for (let file of files) {

      let result = await this.uploadFile(file, this.activatedRoute.params['value'].sin_id + '-' + docName + '_' + (this.docsSubir[documentName].length + 1));

      if (result.message) {
        alert("Se ha producido un error al subir el documento");
        this.cargando = false;
        $('input[type="file"]').val('');
        return;
      }

      let url = result.url;
      let new_name = result.new_name;

      let type = file.type.split('/')[0];

      let document = {
        url: url,
        type: type,
        name: file.name,
        new_name: new_name,
        sindoc_id: sinDocId,
        description: documentName,
      }

      console.log(document);


      this.docsSubidos++;
      this.docsSubir[documentName].push(document);


    }

    $('input[type="file"]').val('');

    this.cargando = false;

  }


  uploadFile(file, name) {

    let size = file.size / 1024 / 1014;

    if (size >= 20) {
      return {
        status: false,
        message: "No se pueden subir archivos con un tamaño superior a 20Mb"
      };
    }

    var formData = new FormData();

    formData.append('select_file', file, file.name);
    formData.append('file_name', name);
    // formData.append('n_files', this.n_files.toString());

    return $.ajax({
      url: this.global.config.api + 'documentacionSiniestros/uploadFile',
      method: "POST",
      data: formData,
      dataType: 'JSON',
      contentType: false,
      cache: false,
      processData: false
    });

  }

  async delete_file(file, index) {

    await this.global.apiCall("documentacionSiniestros/deleteFile", "POST", false, {name: file.new_name});

    this.docsSubir[file.description].splice(index, 1);
    this.docsSubidos--;


  }

  // abrir_documento(key) {

  //   let file = this.docsSubir[key];
  //   // window.open(file.url);
  // }

  async cargarFicheros() {


    // if(Object.keys(this.docsSubir).length == 0){
    if (this.docsSubidos == 0) {
      alert("Debe subir al menos un documento para completar la operación");
      return;
    }


    let result = await this.global.apiCall("documentacionSiniestros/cargarFicheros", "POST", false, {
      ficheros: this.docsSubir,
      sin_id: this.activatedRoute.params['value'].sin_id,
      id_peticion: this.activatedRoute.params['value'].id_peticion
    });


    this.cargando = false;

    if (!result.status) {

      alert(result.message);
      return;

    }

    this.paso = 2;

  }

  parseDate(date) {
    if (date != null) return new Date(date).toLocaleDateString("fr-FR");
    return 'Sin datos';
  }

  clickInput(input) {
    document.getElementById("input_" + input).click();
  }

}
